@import "nlib/config";

.noDataContent {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: $contentDefaultIndent * 2;
  text-align: center;
  .container {
    max-width: 24rem;
    margin: auto;
    .icon {
      svg {
        margin-bottom: $contentDefaultIndent;
        opacity: 0.15;
        font-size: 6rem;
      }
    }
    .title {
      color: $uiDarkColor;
      font-size: 1.25rem;
    }
    .description {
      color: lighten($uiMediumDarkColor, 20%);
      font-size: 1.5rem;
      line-height: 2rem;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: $contentDefaultIndent * 2;
    }
  }
}

[data-mobile] {
  .noDataContent {
    .container {
      .description {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
  }
}
